<template>
  <div>

    <!-- 上边框 -->
    <van-row type="flex" justify="center" style="margin: 1rem">
      <van-image
          round
          cover
          width="5rem"
          height="5rem"
          :src="require('@/pic/pic.jpg')"
      />
    </van-row>


    <van-form style="margin-bottom: 3rem" validate-trigger="onSubmit" @submit="DialogConfirmSubmitOrder" ref="dynamicValidateForm" :model="dynamicValidateForm" :scroll-to-error="true">

      <van-tabs  :v-model="tabsName" @change="changeTabs" @disabled="sorry">

      <div v-show="tabsName!='question'&&tabsName!='jiedan'&&tabsName!='myOrder'">

        <van-cell-group title="温馨提示">

          <van-collapse  v-model="activeNames">
            <van-collapse-item title="关于下单" name="1">
              如果运单比较多,可以分多次提交,忘记录没录,也可以重复提交
              <br/>
              例如: 有50个运单,可以每次提交5个,分10次提交,如果忘记有没有录过,可在录入一次,系统会按照相同运单号覆盖更新,不会重复计算
            </van-collapse-item>
            <van-collapse-item title="关于截单" name="2">
              截单功能会看到自己的(未截)运单,分为敏感和普货,截单之后系统会收到指令,开始打包称重
              <br>如果你的快递已集齐,请点击一键截单,可更快收到包裹
            </van-collapse-item>
            <van-collapse-item title="移除商品包装和备注" name="3">
            移除商品包装,仓库小哥会拆除商品外包装,以获得更小的打包体积,节约您的快递费用~
              <br> 如果你不希望拆除这个商品的外包装, 请选择不移除~
              <br> 如果你有额外的需求,请填写在备注中~
            </van-collapse-item>

          </van-collapse>
<!--          <van-cell >-->
<!--            <b>关于下单</b>-->
<!--            <br/>如果运单比较多,可以分多次提交,忘记录没录,也可以重复提交-->
<!--            <br/>-->
<!--            例如: 有50个运单,可以每次提交5个,分10次提交,如果忘记有没有录过,可在录入一次,系统会按照相同运单号覆盖更新,不会重复计算-->
<!--          </van-cell>-->
<!--          <van-cell>-->
<!--            <b>关于截单</b>-->
<!--            <br>截单功能会看到自己的(未截)运单,分为敏感和普货,截单之后系统会收到指令,开始打包称重-->
<!--            <br>如果你的快递已集齐,请点击一键截单,可更快收到包裹-->
<!--          </van-cell>-->
<!--          <van-cell>-->
<!--            <b>移除商品包装和备注</b>-->
<!--            <br> 移除商品包装,仓库小哥会拆除商品外包装,以获得更小的打包体积,节约您的快递费用~-->
<!--            <br> 如果你不希望拆除这个商品的外包装, 请选择不移除~-->
<!--            <br> 如果你有额外的需求,请填写在备注中~-->
<!--          </van-cell>-->
        </van-cell-group>

        <van-cell-group   title="下单">

              <van-field
                  v-model="receiveName"
                  label="收件人"
                  placeholder="团名+收件名"
                  :rules="[{ required: true, message: '请填写收件人' }]"
              />

              <van-field
                  readonly
                  label="运输方式"
                  :value="transportType"
                  placeholder="选择运输方式"
                  @click="showPicker = true"
              />
              <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="transportTypeSelect"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                />
              </van-popup>

            <van-field
                    readonly
                    clickable
                    label="邮寄方式"
                    :value="postType"
                    placeholder="选择邮寄方式"
                    @click="showPickerPostType = true"
            />
            <van-popup v-model="showPickerPostType" round position="bottom">
              <van-picker
                      show-toolbar
                      :columns="postTypeSelect"
                      @cancel="showPickerPostType = false"
                      @confirm="postTypeOnConfirm"
              />
            </van-popup>

            <div v-if="postType=='拼邮'">
              <van-field
                  readonly
                  clickable
                  label="取货地址"
                  :value="receiveAddress"
                  placeholder="取货地址"
                  @click="showReceiveAddressType = true"
                  :rules="[{ required: true, message: '请选择取货地址' }]"
              />
              <van-popup v-model="showReceiveAddressType" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="receiveAddressSelect"
                    value-key="text"
                    @cancel="showReceiveAddressType = false"
                    @confirm="receiveAddressOnConfirm"
                />
              </van-popup>
            </div>


            <div v-if="postType=='直邮'">

              <van-field
                  v-model="receiveAddressValue"
                  label="收件地址"
                  placeholder="直邮收件地址"
                  :rules="[{ required: true, message: '请填写收件地址' }]"
              />

            </div>


          </van-cell-group>
        </div>

        <van-tab title="普通下单" name="order" >

          <van-cell-group :key="wms.key" v-for="(wms, index) in dynamicValidateForm.wmsOrder" :title="'运单'+(index+1)">

            <van-field
                v-model="wms.goodsName"
                label="商品描述"
                placeholder="名称和数量,例如:快乐零食10包"
            />
            <van-field
                v-model.trim="wms.expressNo"
                label="运单号"
                placeholder="快递单号,非快递公司名称"
                :formatter="formatterWms"
                format-trigger="onChange"
                :rules="[
                   { validator:validatorCN, message: '请勿输入快递公司名称' },
                  { required:true,validator:validatorPattern, message: '请填写正确单号' },
                  { validator:validatorRepeat, message: '单号已存在' }

                ]"
            />
            <van-field
                v-model="wms.expressName"
                label="快递公司"
                placeholder="顺丰/申通等"
            />
              <van-field  label="货物类型">
                <template #input>
                  <van-radio-group v-model="wms.goodsType" direction="horizontal">
                    <van-radio name="普货" shape="square" >普货</van-radio>
                    <van-radio name="敏感" shape="square" >敏感</van-radio>
                  </van-radio-group>
                </template>
              </van-field>

            <van-field
                    label="移除商品包装"
                    placeholder="">
            <template #input>
              <van-radio-group v-model="wms.removePackage" direction="horizontal">
                <van-radio name="移除" shape="square" >移除</van-radio>
                <van-radio name="不移除" shape="square" >不移除</van-radio>
              </van-radio-group>
            </template>
            </van-field>

            <van-field
                    v-model="wms.packageRemark"
                    label="备注"
                    placeholder="有什么需要嘱咐的"
            />

            <van-cell title="操作" v-if="dynamicValidateForm.wmsOrder.length>1">
              <van-button plain hairline type="danger" icon="minus"
                          native-type="button" @click="removeDomain(wms)" size="small">删除
              </van-button>
            </van-cell>
          </van-cell-group>

          <div style="margin: 16px;">
            <van-button icon="plus" plain type="info" native-type="button" @click="addDomain" size="small"> 新增运单</van-button>
          </div>


          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit"  :loading="submitLoading" >提交</van-button>
          </div>

        </van-tab>

        <van-tab title="待截单" name="jiedan" >
          <!-- 可修改文案和加载图标的颜色 -->
<!--          <van-loading v-show="loading" color="#0094ff" />-->

          <van-dropdown-menu >
            <van-dropdown-item @change="selectWmsTransportTypeSelect" title="运输方式" v-model="transportTypeSelectOptionValue" :options="transportType_options" />

            <van-dropdown-item @change="selectWmsPostTypeSelect" title="邮寄方式" v-model="postTypeSelectOptionValue" :options="postType_options" />
          </van-dropdown-menu>

          <van-cell-group title="温馨提示" v-if="commonPackageList.length>0||specialPackageList.length>0">
            <van-cell>
              如果你的快递已集齐,请点击一键截单,可更快收到包裹
            </van-cell>
            <van-cell>
              挂单说明
              <br>
              如果仓库先收到你的包裹时,会挂在你的名下,信息默认是: <strong style="color: red">海运拼邮,移除包装,法拉盛</strong>
              <br> 依赖仓库挂单有可能会出错,为了保险起见,请填写运单信息(系统会根据运单号覆盖信息),方便仓库识别你的包裹
            </van-cell>
          </van-cell-group>


          <van-loading style="margin-top: 20%"  v-show="loading" type="spinner" size="24px" color="#0094ff"  vertical>加载中...</van-loading>
          <van-cell-group title="一件截单"   v-if="!loading" >
            <van-cell title="普货" v-if="commonPackageList.length>0">
              <template #title>
                <van-row type="flex" justify="space-between">
                  <van-col >  普货({{commonPackageList.length}}件)</van-col>
                  <van-col > <van-button v-if="commonPackageList.length>0" size="small"    type="info" native-type="button" @click="puhuoTruncation()">普货一键截单</van-button></van-col>
                </van-row>
              </template>

                <template #label>
                  <div  v-for="(goods,i) in commonPackageList">
                    <!-- 两端对齐 -->
                    <van-row type="flex" justify="space-between" style="padding-bottom: 0.5%">
                      <van-col span="19">{{i+1}} -{{goods.goodsName}} </van-col>
                    </van-row>

                    <van-row type="flex" justify="space-between" >
                      <van-col > {{goods.expressNo}}  <van-icon name="completed" color="blue"   @click="copyActiveCode($event,goods.expressNo )">复制</van-icon></van-col>
                      <van-col > {{goods.transportType}}-{{goods.postType}}-{{goods.expressType}}</van-col>
                    </van-row>
                    <van-row  type="flex" justify="space-between" >
                      <van-col > {{goods.removePackage}}包装 - {{goods.packageRemark}}</van-col>
                      <van-col > {{goods.expressApplyTime}}</van-col>
                    </van-row>

                    <van-row  type="flex" justify="space-between" >
                      <van-col > {{goods.receiveAddress}}</van-col>
                    </van-row>
                    <van-divider v-if="i<commonPackageList.length-1"/>
                  </div>

                </template>

            </van-cell>
            <van-cell title="敏感" v-if="specialPackageList.length>0">
              <template #title>


                <van-row type="flex" justify="space-between">
                  <van-col >    敏感({{specialPackageList.length}}件)</van-col>
                  <van-col >    <van-button v-if="specialPackageList.length>0"   size="small"  type="info" native-type="button" @click="minganTruncation()">敏感一键截单</van-button></van-col>
                </van-row>
              </template>
              <template #label >

                <div v-for="(sgoods,i)  in specialPackageList">

                  <!-- 两端对齐 -->
                  <van-row type="flex" justify="space-between" style="padding-bottom: 0.5%">
                    <van-col span="19">{{i+1}} -{{sgoods.goodsName}} </van-col>
                  </van-row>
<!--                  <van-row type="flex" justify="space-between" >-->
<!--                    <van-col > {{sgoods.expressNo}}</van-col>-->
<!--                    <van-col > {{sgoods.expressApplyTime}}</van-col>-->
<!--                  </van-row>-->


                  <van-row type="flex" justify="space-between" >

                    <van-col > {{sgoods.expressNo}}<van-icon name="completed" color="blue" v-if="sgoods.expressType=='挂单'"  @click="copyActiveCode($event,sgoods.expressNo )">复制</van-icon></van-col>
                    <van-col > {{sgoods.transportType}}-{{sgoods.postType}}-{{sgoods.expressType}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{sgoods.removePackage}}包装 - {{sgoods.packageRemark}}</van-col>
                    <van-col > {{sgoods.expressApplyTime}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{sgoods.receiveAddress}}</van-col>
                  </van-row>


                  <van-divider v-if="i<specialPackageList.length-1"/>

                </div>
              </template>

            </van-cell>
            <van-cell v-if="!(commonPackageList.length>0||specialPackageList.length>0)">
              暂无需要截单数据

            </van-cell>
<!--            <van-cell >-->
<!--            <van-row type="flex" justify="space-around">-->

<!--            </van-row>-->
<!--            </van-cell>-->
          </van-cell-group>
        </van-tab>
        <van-tab title="常见问题" name="question">

          <help/>
        </van-tab>
        <van-tab title="批量识别" name="distinguish" disabled >
          <van-cell-group title="批量识别(多个运单请换行)">
            <van-field
                v-model="content"
                rows="5"
                autosize
                label="运单信息"
                type="textarea"
                placeholder="商品;快递公司;运单号;货物类型"

            />
          </van-cell-group>

          <van-cell >
            <div style="color: #646566;word-wrap:break-word">
              <span>举个栗子🌰: <van-icon name="completed"  style="font-size: 10px" @click="copyActiveCode($event,example )">复制</van-icon>
          <br></span>
              麻辣鸡爪10袋;顺丰;SF3065465;普货<br>
              快乐无边;顺丰;SF3065465412;普货<br>
              点读机;申通;ST200202221;敏货
            </div>
          </van-cell>

          <div style="margin: 16px;">
            <van-button  block  type="info" native-type="button" @click="addDomain">识别</van-button>
          </div>

        </van-tab>


      </van-tabs>
    </van-form>

     <van-tabbar route :placeholder="true" safe-area-inset-bottom  style="margin-top: 1rem" >
      <van-tabbar-item replace to="/wms" icon="home-o">下单</van-tabbar-item>
      <van-tabbar-item replace to="/order" icon="search"  >我的订单</van-tabbar-item>
    </van-tabbar>

    <!-- 开启底部安全区适配 -->
<!--    <van-number-keyboard safe-area-inset-bottom />-->

  </div>
</template>

<script>
import {Checkbox, CheckboxGroup, Card, SubmitBar, Toast, Form, Button} from 'vant';
import Clipboard from 'clipboard'
import { Dialog } from 'vant';
import { submitOrder,queryWaitTruncationOrder,updateTruncation } from '@/api/order/order';
import help from '../help'
import Axios from "axios";

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Form.name]: Form,
    help
  },

  data() {
    return {
      transportTypeSelectOptionValue: 'all',
      postTypeSelectOptionValue: 'all',
      transportType_options: [
        { text: '全部', value: 'all' },
        { text: '海运', value: '海运' },
        { text: '空运', value: '空运' },
        { text: '限时达海运', value: '限时达海运' },
      ],
      postType_options: [
        { text: '全部', value: 'all' },
        { text: '拼邮', value: '拼邮' },
        { text: '直邮', value: '直邮' },
      ],
      activeNames: [],
      loading:false,
      submitLoading:false,
      example:' 麻辣鸡爪10袋;顺丰;SF3065465;普货\n' +
              '快乐无边;顺丰;SF3065465412;普货',
      code:'仓库地址...深圳地址少时诵诗书所所所所所',
      tabsName:'order',
      content: '',
      customerCode:'',
      showPicker: false,
      transportType: '海运',
      postType: '拼邮',
      showPickerPostType:false,
      receiveName: '',
      receiveAddress: '',
      receiveAddressValue: '',
      showReceiveAddressType:false,
      // '5 Pearce place,great neck,new york,11021',
      receiveAddressSelect: [
        {
          text:'旺财法拉盛',
          value: '4516 Robinson st,Flushing,NY11355'
        },
        {
          text:'anaitis+',
          value: '25 Gilchrest Rd NY11021'
        },
        {
          text:'Jerico',
          value: ' linden place glen head'
        },
        {
          text:'Buffalo',
          value: ' 2711 Sheridan Drive Apt.3 '
        },
        {
          text:'AL YONKERS',
          value:'21 Hudson View Dr,Yonkers,NY 10701'
        }

      ],
      transportTypeSelect: [
          '海运','空运','限时达海运'
      ],
      postTypeSelect: [
        '拼邮','直邮'
      ],

      removePackageSelect: [
        '移除','不移除'
      ],
      domains: [{
        value: ''
      }],
      dynamicValidateForm: {
        wmsOrder: [{
          goodsName: '',
          expressNo: '',
          expressName: '',
          removePackage: '移除',
          packageRemark: '',
          goodsType: '普货',
          showGoodsType:false
        }],
        email: ''
      },
      commonPackageList:[],
      specialPackageList:[],
      wmsTips:"格式如下:</br>" +
          "麻辣鸡爪10袋;顺丰;SF3065465;普货\n" +
          "快乐无边;顺丰;SF3065465412;普货\n" +
          "点读机;申通;ST200202221;敏货"
    };
  },

  computed: {
  },

  methods: {
    // 格式化
    formatterWms(value) {
      // 过滤输入的数字
      return value.replace(/\s*/g, '').replace('-', '');
    },
    // 运单格式校验 不能是中文 , 切 4 位以上
    validatorPattern(val) {
      return /[a-zA-Z0-9]{4,}/.test(val);
    },
    validatorCN(val) {

      return !/[\u4e00-\u9fa5]/.test(val);
    },
    getResource(){
      const api='https://express.idream-tech.com/resource/resource.js';
      Axios.get(api).then((response)=>{
        console.log(response.data)
        this.receiveAddressSelect=response.data.receiveAddressSelect
      }).catch((error)=>{
        console.log(error);
      })
    },
    validatorRepeat(val) {
      console.log(val)
      let arr=this.dynamicValidateForm.wmsOrder
      let j=0;
      for (let i=0; i<arr.length; i++) {
        if (arr[i].expressNo == val) {
          if(j==1){
            return false;
          }
          // 统计重复
          j++;
        }
      }
      return true;
    },
    changeTabs(name){
      this.tabsName=name

      if(name=='jiedan'){
        this.queryWaitTruncationOrder()
      }
    },

    selectWmsTransportTypeSelect(value){
      this.queryWaitTruncationOrder()
    },
    selectWmsPostTypeSelect(value){
      this.queryWaitTruncationOrder()
    },
    DialogConfirmSubmitOrder(){
      if(this.dynamicValidateForm.wmsOrder.length<1){
        Toast({
          message: '运单不可为空'
        })
        return false
      }
      Dialog.confirm({
        title: '确认提交运单',
        message: `数量为:${this.dynamicValidateForm.wmsOrder.length}件`,
      })
          .then(() => {
            this.confirmSubmitOrder()
          })
          .catch(() => {
            // on cancel
          });
    },

    confirmSubmitOrder(){
      this.submitLoading=true;
      const sendParam={
        orderList:this.dynamicValidateForm.wmsOrder,
        receiveName: this.receiveName,
        receiveAddress: this.receiveAddressValue,
        transportType : this.transportType,
        postType:this.postType,
        customerCode:this.customerCode
      }
      submitOrder(sendParam).then(res=>{
        console.log(res)
        if(res.code='200' ){
          Toast({
            message: '提交成功'
          })
          this.dynamicValidateForm.wmsOrder=[]
          this.addDomain()

          // 缓存 订单信息 便于下次使用
          let saveReceiveInfo={ receiveName:this.receiveName, receiveAddress: this.receiveAddress,receiveAddressValue: this.receiveAddressValue}
          localStorage.setItem('receiveInfo',JSON.stringify(saveReceiveInfo))

        }else{
          Toast({
            message: res.message
          })
        }
        this.submitLoading=false;
      })  .catch(() => {
        Toast({
          message: '系统繁忙,可再次提交'
        })
        this.submitLoading=false;
      });
    },

    queryWaitTruncationOrder(){
      this.loading=true;

      const sendParam={
        postType:this.postTypeSelectOptionValue,
        transportType:this.transportTypeSelectOptionValue,
        customerCode:this.customerCode
      }
      queryWaitTruncationOrder(sendParam).then(res=>{
        this.loading=false;
        this.commonPackageList=[]
        this.specialPackageList=[]
        console.log(res)
        if( res.code='200' ){
          /**
           * 循环分为普货以及敏感
           */
          for(var p in res.data.packageList){
            if(res.data.packageList[p].goodsType=='普货'){
              this.commonPackageList.push(res.data.packageList[p])

            }else{
              this.specialPackageList.push(res.data.packageList[p])
            }
          }

        }
      }) .catch(() => {
        this.loading=false;
        // on cancel
      });
    },
    copyActiveCode(e, text) {
      const clipboard = new Clipboard(e.target, { text: () => text })
      clipboard.on('success', () => {
        Toast({
          message: '复制成功'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        Toast({
          message: '该浏览器不支持自动复制'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.wmsOrder.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.wmsOrder.splice(index, 1)
      }
    },
    addDomain() {
      // TODO .. check exits
      if(!this.checkExitsExpressValid(this.dynamicValidateForm.wmsOrder)){

        return false;
      }

      this.dynamicValidateForm.wmsOrder.push({
        goodsName: '',
        expressNo: '',
        expressName: '',
        removePackage: '移除',
        packageRemark: '',
        goodsType: '普货',
        key: Date.now()
      });
    },
    checkExitsExpressValid(arr){
      for (var i=0; i<arr.length; i++) {
        for (var j=i+1; j<arr.length; j++) {
          if (arr[i].expressNo == arr[j].expressNo) {
            Toast({
              message: '单号:'+arr[i].expressNo+'已存在'
            })
            return false;
          }
        }
      }
      return true;
    },
    onConfirm(value) {
      this.transportType = value;
      this.showPicker = false;

    },
    postTypeOnConfirm(value) {
      if( this.postType!=value){
        this.postType = value;
        this.receiveAddress='';
        this.receiveAddressValue ='';
      }
      this.showPickerPostType = false;
    },
    receiveAddressOnConfirm(select) {
      this.receiveAddress = select.text;
      this.receiveAddressValue = select.value;
      this.showReceiveAddressType = false;
    },
    sorry(){
        Toast({
          message: '即将上线'
        })
    },
    puhuoTruncation(){

      Dialog.confirm({
        title: '确认普货截单',
        message: `数量为:${this.commonPackageList.length}件`,
      })
          .then(() => {
            // on confirm
            const puhuo = {
              postType:this.postTypeSelectOptionValue,
              transportType:this.transportTypeSelectOptionValue,
              customerCode:this.customerCode,
              goodsType:'普货'
            }
            updateTruncation(puhuo).then(res=>{
              if( res.code='200' ){
                Toast({
                  message: '普货截单成功'
                })
                this.queryWaitTruncationOrder()
              }
            })


          })
          .catch(() => {
            // on cancel
          });

    },
    minganTruncation(){

      Dialog.confirm({
        title: '确认敏感截单',
        message: `数量为:${this.specialPackageList.length}件`,
      })
          .then(() => {
            // on confirm
            const mingan = {
              postType:this.postTypeSelectOptionValue,
              transportType:this.transportTypeSelectOptionValue,
              customerCode:this.customerCode,
              goodsType:'敏感'
            }
            updateTruncation(mingan).then(res=>{
              if( res.code='200' ){
                Toast({
                  message: '敏感截单成功'
                })
                this.queryWaitTruncationOrder()
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    }
  },
  created() {

    this.getResource()

    let token = this.$route.query.token
    if(!token||token==''){
      token=JSON.parse(localStorage.getItem("loginToken")|| '{}').loginToken
    }

    console.log('this is token')
    console.log(token)
    if(!token||token==''){
      this.$router.push({ name: 'guide' })
    }else{
      /**
       * 登录 token 保存
       *
       * */
      localStorage.setItem("loginToken",JSON.stringify({loginToken:token}))


      this.customerCode = token
      this.queryWaitTruncationOrder()

      // 读取地址信息
      let saveReceiveInfo=JSON.parse(localStorage.getItem("receiveInfo") || '{}')
      if(saveReceiveInfo&&saveReceiveInfo.receiveName){
        this.receiveName=saveReceiveInfo.receiveName
        this.receiveAddress=saveReceiveInfo.receiveAddress
        this.receiveAddressValue=saveReceiveInfo.receiveAddressValue
      }
    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
