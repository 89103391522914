<template>
  <div>

    <van-cell-group title="仓库地址">
      <van-cell>

        <template #default>
          {{ address1 }}
          <br>
          {{ address2 }}
          <br>
          {{ address3 }}
          <van-icon name="completed" @click="copyActiveCode($event,address )">复制</van-icon>
        </template>

      </van-cell>
    </van-cell-group>

    <van-cell-group title="空运价格">
      <van-cell>
        <b>空运dhl41/斤，时效3-8个工作日</b>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="拼邮价格">
      <van-cell>
        <b>普货限时达33元/kg，时效28-30天</b>
        <b>海运普货22元/kg，时效1-2个月</b>
        <br>🈲刀具液体粉末充电宝，禁高仿大牌
        <br>起拼重量5kg，拼满101kg发货，不接急单！
        可满21kg直邮自己家，运费为25/kg
      </van-cell>
      <van-cell>
        <b>海运敏感货33元/kg，时效2个月左右</b>
        <br>🉑寄液体少量，食品，化妆品，药品），茶叶，干海鲜等
        <br>🈲香烟
        <br>另外需要密封好食品保质期超过3个月，起拼重量5kg，101kg发货，不接急单！ 可满21kg直邮自己家，运费为35/kg
      </van-cell>
      <van-cell>
        年底物流繁忙，时效可能会延迟，急单请空运
      </van-cell>
    </van-cell-group>



    <van-cell-group title="常见问题">
      <van-cell>
        <template>
          ❗️体积系数长宽高/6000，单边长不超过1m，第二边长70cm，海运对长宽限制比较严格，有购买大件得请提前询问群主！
          <br>❗️单件物品超过22.5kg及最长边超过120cm，加收180元/件
          <br>❗️请及时向群主提供物流单号以免延误发货！
          <br>❣️国际物流扣货理赔标准为20/kg+退运费，国内部分货品仓库丢失全额理赔(最高不超过700人民币），易碎品请自行包好。不做理赔处理，谢谢大家
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="交易流程">
      <van-steps :active="4" direction="vertical">
        <van-step>
          ①淘宝下单寄仓库地址，私信给旺财，
          输入"#下单地址#",获得个人专用下单地址
          <br>
          <span style="color: red">收件人格式：团名+收件名,切勿隐藏姓名,否则可能会丢单</span>
        </van-step>
        <van-step>
          ②如果运单全部齐全,请点击截单<br>
          仓库按人名称重，按kg收费，重量确认没问题，微信付款，或者美金实时汇率
        </van-step>

      </van-steps>
    </van-cell-group>

  </div>
</template>

<script>
import {Checkbox, CheckboxGroup, Card, SubmitBar, Toast, Form, Button} from 'vant';
import Clipboard from 'clipboard'
import {Dialog} from 'vant';
import {submitOrder, queryWaitPackageOrder, UpdateCustomsCut} from '@/api/order/order';


export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Form.name]: Form
  },

  data() {
    return {
      example: ' 麻辣鸡爪10袋;顺丰;SF3065465;普货\n' +
          '快乐无边;顺丰;SF3065465412;普货',
      address: '广州市白云区嘉禾街道罗岗七星岗路80号A栋102档\n收件人：旺财+团员名字\n电话：18988848489',
      address1: '广州市白云区嘉禾街道罗岗七星岗路80号A栋102档',
      address2: '收件人：旺财+团员名字 ',
      address3: '电话：18988848489',
      tabsName: 'order',
      content: '',
      customerCode: '',
      showPicker: false,
      transportType: '海运',
      postType: '拼邮',
      showPickerPostType: false,
      receiveName: '',
      receiveAddress: '',
      showReceiveAddressType: false,
      receiveAddressSelect: [
        '25 Gilchrest Rd NY11021', 'linden place glen head', '2711 Sheridan Drive Apt.3', '4516 Robinson st,Flushing,NY11355', '21 Hudson View Dr， Yonkers， NY 10701'
      ],
      transportTypeSelect: [
        '海运', '空运'
      ],
      postTypeSelect: [
        '直邮', '拼邮'
      ],
      domains: [{
        value: ''
      }],
      dynamicValidateForm: {
        wmsOrder: [{
          goodsName: '',
          expressNo: '',
          expressName: '',
          goodsType: '普货',
          showGoodsType: false
        }],
        email: ''
      },
      commonPackageList: [],
      specialPackageList: [],
      commonCountPackageList: [],
      specialCountPackageList: [],
      wmsTips: "格式如下:</br>" +
          "麻辣鸡爪10袋;顺丰;SF3065465;普货\n" +
          "快乐无边;顺丰;SF3065465412;普货\n" +
          "点读机;申通;ST200202221;敏货"
    };
  },

  computed: {},

  methods: {
    copyActiveCode(e, text) {
      const clipboard = new Clipboard(e.target, {text: () => text})
      clipboard.on('success', () => {
        Toast({
          message: '复制成功'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        Toast({
          message: '该浏览器不支持自动复制'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }

  },
  created() {
    // localStore init receive ...


  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
